.login {
  padding: 10px 25px;
  background-color: #f75506;
  border: none;
  border-radius: 30px;
  color: white;
  font-weight: 300;
  font-size: 15px;
  cursor: pointer;
}
.login:hover {
  background-color: white;
  color: #333;
}

/* arun */

.nav-container{
  display: flex;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  margin-top: 10px;
}
.header {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F5F8EA;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px 20px;
    border-radius: 50px;
  }

  .header-logo img {
    height: 70px;
  }

  ul{
    display: flex;
    /* width: 10%; */
    /* flex-direction: row; */
    gap: 10px;
    align-items: center;
    /* justify-content: space-around; */
    /* flex-wrap: wrap; */
  }

  ul li {
    list-style-type: none;
  }

  ul li a{
    text-decoration: none;
  }
  
  .nav-link {
    text-decoration: none;
    color: green;
    font-size: 20px;
    font-weight: bold;
    position: relative;
  }
  
  .nav-link.active::after {
    content: "●"; /* Add the dot */
    font-size: 12px;
    color: green;
    position: absolute;
    bottom: -10px; /* Adjust to position the dot below the text */
    left: 50%;
    transform: translateX(-50%);
  }
  
@media screen and (max-width:420px) {
  .header-logo img{
    width: 80px;
  }
}

