.dynamic-form {
    /* max-width: 500px; */
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .dynamic-form form {
    margin-bottom: 20px;
  }
  
  .dynamic-form h2 {
    text-align: center;
  }

  .dynamic-form h3 {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .dynamic-form label {
    display: block;
    margin-bottom: 2px;
    font-weight: bold;
    color: #140040;
  }
  
  .dynamic-form input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .dynamic-form .form-btn {
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    background-color: #f75506;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .dynamic-form button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .dynamic-form ul {
    list-style: none;
    padding: 0;
    margin: 10px 0;
  }
  
  .dynamic-form li {
    margin-bottom: 5px;
  }
  
  .dynamic-form li strong {
    color: #007bff;
  }
  
  .dynamic-form p {
    text-align: center;
    color: green;
  }
  

  /* schedule table button style */

  .schedule {
    margin-bottom: 10px;
  }

  .schedule th {
    color: #140040;
  }

  .schedule button {
    background: none;
    border: none;
    border-bottom: solid 1px black;
  }