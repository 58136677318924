.table-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}
/*  */
.d-flex {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
.margin-bottom {
  margin-bottom: 30px;
}
.space-between{
  justify-content: space-between;
  align-items: center;
}
/*  */
.date-style-input {
  padding: 3px 5px;
  border: 1px solid rgb(205, 205, 205);
  margin:0px 20px;
}
/*  */
.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

td {
  font-size: 14px;
  color: #393939;
}

.styled-table thead tr {
  /* background-color: #f4f4f4; */
  color: #6c6c6c;
  text-align: left;
  font-size: 14px;
  font-weight: lighter;
}

.styled-table tbody tr {
  border: none;
}

.styled-table tbody tr:hover {
  background-color: #7aad0369; /* Hover effect */
}
.name-cloumn {
  color: #f75506; /* Highlight amount in red */
}
.styled-table tbody tr td:last-child {
  font-weight: bold;
  color: #f75506; /* Highlight amount in red */
}

/* pagenation style */

.pagination-controls {
  display: flex;
  align-items: center;
  /* gap: 8px; */
}

.pagination-btn {
  padding: 8px 12px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #333;
  border-radius: 4px;
  cursor: pointer;
}

.pagination-btn.active {
  background-color: #4caf50;
  color: #fff;
  font-weight: bold;
}

.pagination-btn:disabled {
  background-color: #f0f0f0;
  color: #aaa;
  cursor: not-allowed;
}

.pagination-btn:hover:not(:disabled) {
  background-color: #e6e6e6;
}


/* add dayplace model */

.modal-overlay-delivery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.66);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-outer-delivery{
  background: #f5f8ea;
  border-radius: 8px;
  width: 90%;
  height: 90%;
  padding: 20px;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
}

.modal-outer-delivery img{
  width: 175px;
  position: absolute;
  top: 20px;
  /* right: 25px; */
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  
}

.modal-content-delivery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 700px;
  max-width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.close-modal {
  position: absolute;
  top: 20px;
  right: 25px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
}

/* .close-modal:hover {
  background: #e60000;
} */
