
/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.66);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-outer-container{
  background: #f5f8ea;
  border-radius: 8px;
  width: 90%;
  height: 90%;
  padding: 20px;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
}
.modal-outer-container img{
  width: 175px;
  position: absolute;
  top: 20px;
  /* right: 25px; */
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  
}
.modal-content{
  background: #f5f8ea;
    padding: 40px;
    border-radius: 12px;
    border: 1px solid rgb(202, 202, 202);
    height: 50%;
    width: 380px;
}
.close-btn {
  position: absolute;
  top: 20px;
  right: 25px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.login-form label {
  font-size: 14px;
  font-weight: bold;
}

.login-form input {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Error Message */
.error-message {
  color: red;
  font-size: 12px;
  margin-top: -10px;
}
