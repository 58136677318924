.notification-container {
  position: relative;
}
.notification-hover:hover {
  /* padding: 10px; */
  /* border-radius: 50%; */
  color: rgb(255, 103, 103);
  /* background-color: rgb(254, 185, 185); */
}
.notification-popup {
  animation: fadeIn 0.3s ease-in-out;
}

.badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background: red;
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 12px;
}

/* Simple fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
