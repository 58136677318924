.banner {
  width: 100%;
  display: flex;
  /* padding: 20px; */
  background-color: #F5F8EA;
  color: #333;
  flex-direction: row;
  gap: 20px;
  justify-content: start;
  /* flex-wrap: wrap; */
  margin-top: 2%;
}


.content {
  /* height: 50%; */
  border-left: solid 7px orangered;
  padding-left: 10px;
  margin-left: 20px;
}

.content h1{
  font-size: 60px;
}

.content h2{
  font-size: 46px;
  font-family: Regular, active;
}

.content p {
  font-size: large;
  /* padding-top: 5px; */
}

.banner-info img {
  margin-top: 10px;
  width: 200px;
}

.img {
  border-left: solid 7px orangered;
  padding-left: 10px;
  margin-left: 20px;
}

.banner-buttons {
  display: flex;
}

.banner-buttons button {
  margin-left: 10%;
  padding: 20px 20px;
  font-size: medium;
}

.con-btn{
  background-color: rgb(215, 216, 216);
  /* padding: 1px 10px; */
  border: none;
  border-radius: 30px;
}

.buy-btn{
  background-color: white;
  color: #f75506;
  border: none;
  border-radius: 30px;
  /* text-align: end; */
}

.buy-btn:hover{
  background-color: #f75506;
  color: white;
  cursor: pointer;
}

.banner-images {
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
}

.banner-images img {
  max-width: 100%;
  max-height: 80%;
  margin-right: 10px;
  border-radius: 5px;
}

.formpage{
  width: 30%;
  padding: 20px;
}

  @media screen and (max-width: 480px) {
    .formpage{
      width: 100%;
    }
    .banner{
      flex-wrap: wrap;
    }
    .banner-images{
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 800px) {
    .formpage{
      width: 90%;
    }
    .banner{
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .banner-images{
      flex-wrap: wrap;
    }

  }
  @media screen and (max-width: 1024px) {
    .formpage{
      width: 80%;
    }
    .banner-images{
      width: 80%;
      margin-top: 20px;
    }
    .banner-images img{
      width:50%;
    
    }
    .content h1{
      font-size: 40px;
    }
    .banner-buttons button{
      margin-left: 10%;
      padding: 15px 15px;
      font-size: small;
      font-weight: 500;
    }
  
  }
  @media screen and (max-width: 420px) {
    .content h1{
      font-size: 45px;
    }
    .content h3{
      font-size: 25px;
    }
    .banner-images img{
      width: 100%;
    }
  }
