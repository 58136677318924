.header-info {
  margin-top: 10px;
  background-color: #2A632E;
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: 400;
  padding: 10px;
}

strong {
  color: yellow;
}

@media (max-width: 425px) {
  .delivery-schedule span {
    display: block;
    text-align: left;
  }
}