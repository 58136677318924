label{
  color: #140040;
}

input {
  height: 40px;
  width: 95%;
  margin: 10px 0;
  padding: 8px;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  opacity: 1;
}

select{
  height: 40px;
  width: 100%;
  margin: 10px 0;
  padding: 8px;
  background-color: white;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  opacity: 1;
}

.text-area{
  height: 70px;
  width: 95%;
  margin: 10px 0;
  padding: 8px;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  opacity: 1;
}

.inner-form h2{
  text-align: center;
  margin-bottom: 10px;
}

.inner-form{
  background-color: #D8DBCD;
  padding: 20px;
  /* height: 85%; */
}

.day-place{
  display: flex;
  flex-direction: row;
  gap: 50px;
  flex-wrap: wrap;
}

.ltrs{
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.payment{
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-wrap: wrap;
}

/* aerrow button */

.btn{
  padding: 5px;
  height: 30px;
  width: 80px;
  font-weight: bold;
  background-color: #f75506;
   border-radius: 15px;
   border: none;
   color: white;
   cursor: pointer;
 }

/* .btn:hover{
  background-color: #0B0D39;
  color: white;
  
} */

/* succes page */
.success-page{
  background-color: rgba(0, 0, 0, .1);
  text-align: center;
  padding: 30px;
}

.success-page img{
  width: 100%;
  margin: 10px;
}

@media screen and (max-width: 480px) {
  .inner-form{
    width: 100%;
  }
  
}